export default {
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'comercio Electrónico',
  Calendar: 'Calendario',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Factura',
  List: 'Lista',
  Preview: 'Vista Previa',
  Edit: 'Editar',
  Add: 'Agregar',
  User: 'Usuario',
  'User List': 'Lista de Usuarios',
  'User View': 'Vista de Usuarios',
  'APPS AND PAGES': 'APPS Y PÁGINAS',
  Pages: 'Páginas',
  Authentication: 'Autenticación',
  'Login V1': 'Inicio de Sesión V1',
  'Login V2': 'Inicio de Sesión V2',
  'Register V1': 'Registro V1',
  'Register V2': 'Registro V2',
  'Forgot Password V1': 'Has Olvidado Tu Contraseña V1',
  'Forgot Password V2': 'Has Olvidado Tu Contraseña V2',
  'Reset Password V1': 'Reiniciar Contraseña V1',
  'Reset Password V2': 'Reiniciar Contraseña V2',
  Miscellaneous: 'Misceláneo',
  'En Mantenimiento': 'En Mantenimiento',
  Error: 'Error',
  'Coming Soon': 'Próximamente',
  'Not Authorized': 'No Autorizado',
  'Knowledge Base': 'Base de Conocimiento',
  'Account Settings': 'Configuración de la Cuenta',
  Pricing: 'Precios',
  FAQ: 'FAQ',
  'USER INTERFACE': 'INTERFAZ DE USUARIO',
  Typography: 'Tipografía',
  Icons: 'Iconos',
  Gamification: 'Gamificación',
  Cards: 'Tarjetas',
  Basic: 'Básico',
  Statistics: 'Estadísticas',
  Advance: 'Avanzar',
  Actions: 'Acciones',
  Chart: 'Gráfica',
  Components: 'Componentes',
  Alert: 'Alerta',
  Avatar: 'Avatar',
  Badge: 'Insignia',
  Button: 'Botón',
  Menu: 'Menú',
  'Expansion Panel': 'Panel de Expansión',
  Dialog: 'Diálogo',
  Pagination: 'Paginación',
  Tabs: 'Pestañas',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Línea de tiempo',
  Treeview: 'Vista de árbol',
  'FORMS & TABLES': 'FORMULARIOS & TABLAS',
  'Forms Elements': 'Elementos de los Formularios',
  Autocomplete: 'Autocompletar',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'Entrada de Archivos',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Deslizador',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Selector de Fecha',
  'Month Picker': 'Selector de Mes',
  'Time Picker': 'Selector de Tiempo',
  Rating: 'Clasificación',
  'Form Layout': 'Diseño de Formularios',
  'Form validation': 'Validación de Formularios',
  Tables: 'Tablas',
  'Simple Table': 'Tabla Simple',
  Datatable: 'Tabla de Datos',
  'CHARTS AND MAPS': 'GRÁFICAS Y MAPAS',
  Charts: 'Gráficas',
  'Apex Chart': 'Gráfica Apex',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Otros',
  'Menu Levels': 'Niveles del Menú',
  'Menu Level 2.1': 'Nivel del Menú 2.1',
  'Menu Level 2.2': 'Nivel del Menú 2.2',
  'Menu Level 3.1': 'Nivel del Menú 3.1',
  'Menu Level 3.2': 'Nivel del Menú 3.2',
  'Disabled Menu': 'Menú Deshabilitado',
  'Raise Support': 'Aumentar Apoyo',
  Documentation: 'Documentación',
  'Charts & Maps': 'Gráficas & Mapas',
  Apps: 'Apps',
  'User Interface': 'Interfaz de Usuario',
  'Forms & Tables': 'Formas & Tablas',
}
